import oldPortfolio from '../media/portfolioold.png';
import portfolio from '../media/fotor-video.mp4';
import mockVideo from '../media/clipbait/mock.mp4'
import techStudio from '../media/Tech studio Mock.mp4'

export const projects = [
    {
        name: "Dont Look This Project",
        imgSrc: oldPortfolio,
        opened: true,
    },
    {
        name: "Portfolio Website",
        imgSrc: portfolio,
        opened: true,
    },
    {
        name: "Clipbait Media",
        imgSrc: mockVideo,
        opened: true,
    },
    {
        name: "Tech Studio",
        imgSrc: techStudio,
        opened: true,
    },
];


