import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import './navbar.css';

const Navbar = () => {
    const [activeTab, setActiveTab] = useState('');


    return (


        <div className="navbar">
            <div className="radio-inputs">
                <label className="radio">
                    <input
                        type="radio"
                        name="radio"
                        checked={activeTab === 'Home'}
                        onClick={() => setActiveTab('Home')}
                    />
                    <NavLink to="/" className={`name ${activeTab === 'Home' ? 'active' : ''}`}>Home</NavLink>
                </label>
                <label className="radio">
                    <input
                        type="radio"
                        name="radio"
                        checked={activeTab === 'Profile'}
                        onClick={() => setActiveTab('Profile')}
                    />
                    <NavLink to="/profile" className={`name ${activeTab === 'Profile' ? 'active' : ''}`}>Profile</NavLink>
                </label>
                <label className="radio">
                    <input
                        type="radio"
                        name="radio"
                        checked={activeTab === 'Contact'}
                        onClick={() => setActiveTab('Contact')}
                    />
                    <NavLink to="/contact" className={`name ${activeTab === 'Contact' ? 'active' : ''}`}>Contact</NavLink>
                </label>
            </div>
        </div>

    );
};

export default Navbar;