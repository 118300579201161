import React from 'react';
import './project4.css';
import laptop from '../../../../media/clipbait/Clipbait Mockup screen1.png'
import { Link } from 'react-router-dom';
import screen2 from '../../../../media/clipbait/screen2.png'
import screen3 from '../../../../media/clipbait/screen3.png'
import iphoneMockup from '../../../../media/clipbait/clipbait iphone mockup-vmake.mp4'
import { useInView } from 'react-intersection-observer';
import Footer from '../../../footer/footer';
const Project4 = () => {

    const [ref1, inView1] = useInView({
        threshold: 0.5, triggerOnce: true,
    })

    return (
        <>
            <Link to="/" className="circle-close-icon" />

            <div className='Project3'>

                <div className="project-quote">
                    <h2>Bringing the Future to Your Fingertips.</h2>
                    <div className="tag">
                        TechStudio
                    </div>
                </div>

                <div className="laptop-image"><img src={laptop} alt="mockup" /></div>
                <div className="project-grid-images">
                    <div className="image" style={{ height: 440 }}><img style={{ objectFit: 'contain', }} src={screen2} alt="websitesample" /></div>
                    <div className="image" style={{ height: 440 }}><img style={{ objectFit: 'contain', }} src={screen3} alt="websitesample" /></div>

                </div>

                <div className="project-overview">
                    <p>Overview <br /><br />

                        I redesigned the Clipbait Media website to enhance its visual appeal and user experience. This project involved creating a dynamic, user-friendly interface that effectively showcases the company's post-production services. The new design emphasizes creativity and innovation, aligning with Clipbait Media's commitment to transforming everyday experiences through cutting-edge tools and techniques.
                    </p>
                </div>

                <div className="project-iphone-mockup">
                    <video src={iphoneMockup} autoPlay
                        muted

                        style={{
                            width: '100%',
                            height: '100%',
                            objectFit: 'cover',
                            borderRadius: 12,
                        }} />
                </div>

                <div className={`contact ${inView1 ? 'slide-in' : ''}`} ref={ref1} style={{ height: '50vh' }}>
                    <div className="Contact-desc">
                        <h1>Let's work together.</h1>
                    </div>
                    <div className="Contact-call">
                        <a href="mailto:murphysam.0500@gmail.com">Get in touch.</a>
                    </div>
                </div>
                <Footer position='relative' />

            </div>

        </>
    );
};

export default Project4;

