import React from 'react';
import './footer.css';
import '../../index.css'
const GIF = require('./Giffy logo.gif')


const Footer = ({ position = 'fixed' }) => {
    return (

        <div className='footer' style={{ position }}>
            <div className="footer-container">


                <div className="footer-hero">
                    <img src={GIF} alt="SamJoey" />

                    <span>&copy; 2024 Sam Joey.</span>
                </div>

                <div className="footer-links">
                    <a href="/">Twitter</a>
                    <a href="https://www.linkedin.com/in/murphy0510/">LinkedIn</a>
                    <a href="mailto:murphysam.0500@gmail.com">Mail</a>
                </div>

            </div>



        </div>


    );
};

export default Footer;