import React from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import Navbar from './components/Navbar/navbar.jsx';
import Hero from './components/hero/hero.jsx';
import Profile from './components/Profile/profile.jsx';
import Contact from './components/Contact/contact.jsx';
import Project2 from './components/Project/Projects/Project2/Project2.jsx';
import Project3 from './components/Project/Projects/Project3/Project3.jsx';
import Project4 from './components/Project/Projects/Project4/Project4.jsx';
import Project1 from './components/Project/Projects/Project1/Project1.jsx';



function App() {
  return (
    <Router>
      <AppContent />
    </Router>
  );
}

function AppContent() {
  const location = useLocation();
  const projectPaths = ['/projects/Portfolio', '/projects/ClipbaitMedia', '/projects/TechStudio', '/projects/OldPortfolio'];
  const shouldShowNavbar = !projectPaths.includes(location.pathname);

  return (
    <>
      {shouldShowNavbar && <Navbar />}
      <Routes>
        <Route exact path="" element={<Hero />} />
        <Route path="/profile" element={<Profile />} />
        <Route path="/contact" element={<Contact />} />

        <Route path="/projects/OldPortfolio" element={<Project1 />} />
        <Route path="/projects/Portfolio" element={<Project2 />} />
        <Route path="/projects/ClipbaitMedia" element={<Project3 />} />
        <Route path="/projects/TechStudio" element={<Project4 />} />
      </Routes>
    </>
  );
}

export default App;
