import './hero.css';
import { useInView } from "react-intersection-observer";
import Project from '../Project/project.jsx';
import { projects } from '../../extra-files/constants.js';
import Footer from '../footer/footer.jsx'
import { useEffect } from 'react';




const Hero = () => {

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    const [ref1, inView1] = useInView({
        threshold: 0.5, // Adjust the threshold as needed
        triggerOnce: true,
    });

    const [ref2, inView2] = useInView({
        threshold: 0.2, // Adjust the threshold as needed
        triggerOnce: true,
    });

    const [ref3, inView3] = useInView({
        threshold: 0.5, // Adjust the threshold as needed
        triggerOnce: true,
    });


    return (
        <div className="Hero">
            <div className={`hero-description ${inView1 ? 'hero-slidein' : ''}`} ref={ref1}>
                <div className="hero-tag-desc">A passionate developer creating captivating digital experiences.</div>
                <div className="hero-expertise">
                    Expertise
                    <div className="tag">FrontEnd Dev</div>
                    <div className="tag">UI Designer</div>
                    <div className="tag">Product</div>

                </div>

            </div>

            <div className={`images ${inView2 ? 'project-slide-in' : ''}`} ref={ref2}>
                {projects.map((project, index) => (
                    <Project key={index} project={project} className='projects' />
                ))}
            </div>

            <div className={`contact ${inView3 ? 'slide-in' : ''}`} ref={ref3} style={{ height: '50vh' }}>
                <div className="Contact-desc">
                    <h1>Let's work together.</h1>
                </div>
                <div className="Contact-call">
                    <a href="mailto:murphysam.0500@gmail.com">Get in touch.</a>
                </div>
            </div>
            <Footer position='relative' />
        </div>
    );
}

export default Hero;