import Footer from "../footer/footer";
import { useInView } from "react-intersection-observer";
import './contact.css';

const Contact = () => {
    const [ref, inView] = useInView({
        threshold: 0.5, // Adjust the threshold as needed
        triggerOnce: true,
    });

    return (
        <>
            <div className={`contact ${inView ? 'slide-in' : ''}`} ref={ref}>
                <div className="Contact-desc">
                    <h1>Let's work together.</h1>
                </div>
                <div className="Contact-call">
                    <a href="mailto:murphysam.0500@gmail.com">Get in touch.</a>
                </div>
            </div>
            <Footer position="fixed" />
        </>
    );
};

export default Contact;