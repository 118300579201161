// src/components/Projects/Project1.jsx
import { React, useEffect } from 'react';
import { useInView } from 'react-intersection-observer';
import './project1.css';
import portfolio from '../../../../media/OldPortfolio/oldportfolio.png';
import design from '../../../../media/OldPortfolio/oldportfolio.mp4'

import { Link } from 'react-router-dom';
import Footer from '../../../footer/footer';

const Project1 = () => {


    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);


    const [ref3, inView3] = useInView({
        threshold: 0.5, // Adjust the threshold as needed
        triggerOnce: true,
    });


    return (
        <>
            <Link to="/" className="circle-close-icon" />

            <div className='Project1'>


                <div className="project-quote">
                    <h2>It was my 1st ever Portfolio<br />So please take a fast look 😅</h2>
                    <div className="tag">
                        Portfolio
                    </div>
                </div>

                <div className="project-iphone-mockup">
                    <img src={portfolio}
                        alt='design'

                        style={{
                            width: '100%',
                            height: '100%',
                            objectFit: 'cover',
                        }} />
                </div>







                <div className="project-overview">
                    <p>Overview <br /><br />

                        Welcome to my very first portfolio! This project represents the start of my journey into the world of web development. While it may not be perfect, it showcases my initial steps in designing and building a personal website.
                    </p>
                </div>

                <div className="project-iphone-mockup" style={{ backgroundColor: '#F4F3F1' }}>
                    <video src={design} autoPlay
                        muted

                        style={{
                            width: '100%',
                            height: '100%',
                            objectFit: 'cover',
                            borderRadius: 12,
                        }} />
                </div>




                <div className={`contact ${inView3 ? 'slide-in' : ''}`} ref={ref3} style={{ height: '50vh' }}>
                    <div className="Contact-desc">
                        <h1>Let's work together.</h1>
                    </div>
                    <div className="Contact-call">
                        <a href="mailto:murphysam.0500@gmail.com">Get in touch.</a>
                    </div>
                </div>
                <Footer position='relative' />



            </div>

        </>
    );
};

export default Project1;

