import './profile.css'
import Footer from '../footer/footer';
import hero1 from '../../media/hero1.jpg';
import hero3 from '../../media/hero3.jpg';
import { useInView } from 'react-intersection-observer';
import { useEffect } from 'react';


const Profile = () => {

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    const [ref1, inView1] = useInView({
        threshold: 0.1,
        triggerOnce: true,
    })

    const [ref3, inView3] = useInView({
        threshold: 0.5,
        triggerOnce: true,
    });
    return (
        <div className={`profile ${inView1 ? 'profile-slidein' : ''}`} ref={ref1} >
            <div className="hero-greeting-name">
                Hey, I'm Sam Joey. 🖖🏻
            </div>

            <div className="hero-image">
                <img src={hero1} alt="Hero" />
                <img src={hero3} alt="hero" />
            </div>

            <div className="hero-about">
                <div className="tag">About</div>
                <h2>I am a front-end developer and designer based in India. While I am continually developing my expertise, I have a passion for crafting visually appealing and user-friendly websites.</h2>
            </div>

            <div className="hero-experience">
                <div className="hero-exp">
                    <div className="tag">Experince</div>
                    <h2>Where I've worked</h2>
                </div>
                <div className="experience-container">
                    <div className="experience">
                        <p>2019</p>
                        <h3>Infoziant</h3>
                        <p>Front End Web Dev Intern</p>
                        <div className="tag">Internship</div>
                    </div>

                    <div className="experience">
                        <p>2022 - 2023</p>
                        <h3>Cognizant</h3>
                        <p>Programmer Analyst Trainee</p>
                        <div className="tag">Permanent</div>
                    </div>
                </div>
            </div>

            <div className="hero-skills">
                <div className="skill-container">
                    <div className="tag">Skills</div>
                    <h2>Some of the key skills I bring to the table</h2>
                </div>

                <div className="skills">
                    <p>Python</p>
                    <p>SQL</p>
                    <p>React Js</p>
                    <p>HTML</p>
                    <p>CSS</p>
                    <p>Figma</p>
                    <p>UI Design</p>
                </div>

            </div>


            <div className={`contact ${inView3 ? 'slide-in' : ''}`} ref={ref3} style={{ height: '30vh' }}>
                <div className="Contact-desc">
                    <h1>Let's work together.</h1>
                </div>
                <div className="Contact-call">
                    <a href="mailto:murphysam.0500@gmail.com">Get in touch.</a>
                </div>
            </div>
            <Footer position='relative' />


        </div>
    );
}

export default Profile;