import React from 'react';
import { Link } from 'react-router-dom'; // Import Link
import './project.css';
import arrow from '../../media/arrow.png';
import lock from '../../media/lock.png';

function Project({ project }) {
    const isVideo = project.imgSrc && project.imgSrc.endsWith('.mp4');

    // Map project names to their respective routes
    const getProjectLink = (projectName) => {
        switch (projectName) {
            case "Portfolio Website":
                return "/projects/Portfolio";
            case "Clipbait Media":
                return "/projects/ClipbaitMedia";

            case "Tech Studio":
                return "/projects/TechStudio";

            case "Dont Look This Project":
                return '/projects/OldPortfolio';

            // Add other cases here as needed for other projects
            default:
                return "/";
        }
    };

    return (
        <Link to={getProjectLink(project.name)}>
            <div className="project-container">
                {isVideo ? (
                    <video
                        src={project.imgSrc}
                        autoPlay
                        muted

                        style={{
                            width: '100%',
                            height: '100%',
                            objectFit: 'cover',
                        }}
                    />
                ) : (
                    <img
                        src={project.imgSrc}
                        alt={project.name}
                        className="project-image"
                    />
                )}
                <div className="project-nav-container">
                    <div className="project-nav-header">
                        <h2 className="project-name">{project.name}</h2>
                        <div className="icon">
                            <img src={project.opened ? arrow : lock} alt={project.opened ? 'arrow' : 'locked'} className="icon-image" />
                        </div>
                    </div>
                </div>
            </div>
        </Link>
    );
}

export default Project;
