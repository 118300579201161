// src/components/Projects/Project1.jsx
import { React, useEffect } from 'react';
import { useInView } from 'react-intersection-observer';
import './project2.css';

import iphoneMockup from '../../../../media/iphonemockup.mp4'

import portfolio from '../../../../media/fotor-video.mp4';
import iphone from '../../../../media/iphone.png'
import tablet from '../../../../media/tablet.png'
import laptop from '../../../../media/laptop.png'

import sample1 from '../../../../media/sample1.png'
import sample2 from '../../../../media/sample2.png'
import { Link } from 'react-router-dom';
import Footer from '../../../footer/footer';

const Project2 = () => {


    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);


    const [ref3, inView3] = useInView({
        threshold: 0.5, // Adjust the threshold as needed
        triggerOnce: true,
    });


    return (
        <>
            <Link to="/" className="circle-close-icon" />

            <div className='Project2'>
                {/* <div className="circle-close-icon"><img src={close} alt="close" /></div> */}

                <div className="project-quote">
                    <h2>Bringing Vision to Life with Elegant Design</h2>
                    <div className="tag">
                        Portfolio
                    </div>
                </div>

                <div className="project-iphone-mockup">
                    <video src={portfolio} autoPlay
                        muted

                        style={{
                            width: '100%',
                            height: '100%',
                            objectFit: 'cover',
                        }} />
                </div>




                <div className="project-grid-images">
                    <div className="image"><img src={iphone} alt="mockup" /></div>
                    <div className="image"><img src={tablet} alt="mockup" /></div>

                </div>
                <div className="laptop-image"><img src={laptop} alt="mockup" /></div>


                <div className="project-overview">
                    <p>Overview <br /><br />

                        I designed and developed my portfolio using React, enhancing my skills in front-end development. This project allowed me to implement design principles, learn React techniques, and create a dynamic and responsive user interface.

                    </p>
                </div>

                <div className="project-iphone-mockup">
                    <video src={iphoneMockup} autoPlay
                        muted

                        style={{
                            width: '100%',
                            height: '100%',
                            objectFit: 'cover',
                            borderRadius: 12,
                        }} />
                </div>


                <div className="project-grid-images">
                    <div className="image" style={{ height: '30vh' }}><img style={{ objectFit: 'contain', }} src={sample1} alt="websitesample" /></div>
                    <div className="image" style={{ height: '30vh' }}><img style={{ objectFit: 'contain', }} src={sample2} alt="websitesample" /></div>

                </div>



                <div className={`contact ${inView3 ? 'slide-in' : ''}`} ref={ref3} style={{ height: '50vh' }}>
                    <div className="Contact-desc">
                        <h1>Let's work together.</h1>
                    </div>
                    <div className="Contact-call">
                        <a href="mailto:murphysam.0500@gmail.com">Get in touch.</a>
                    </div>
                </div>
                <Footer position='relative' />



            </div>

        </>
    );
};

export default Project2;

